import React from "react"
import PropTypes from "prop-types"
import reviewImage from "../images/review.png"
import reviewNurse from "../images/nurseReview.png"
import Text from "../text.json"
import { Carousel, Image } from "react-bootstrap"
import "./styles/homepage__review.scss"

const Review = ({ french }) => {
  return (
    <section className="review--section">
      <Carousel indicators={false} controls={false}>
        <Carousel.Item>
          <div className="review__container">
            <h2>
              {
                Text[french ? "fr" : "en"]["homepage-review"][
                  "review-1-heading"
                ]
              }
            </h2>
            <p className="review__review">
              {
                Text[french ? "fr" : "en"]["homepage-review"][
                  "review-1-comment"
                ]
              }
            </p>
            <Image src={reviewImage} alt="Review Author" roundedCircle></Image>
            <p className="review__author">
              {Text[french ? "fr" : "en"]["homepage-review"]["review-1-author"]}
            </p>
            <p className="review__company">
              {
                Text[french ? "fr" : "en"]["homepage-review"][
                  "review-1-company"
                ]
              }
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="review__container">
            <h2>
              {
                Text[french ? "fr" : "en"]["homepage-review"][
                  "review-2-heading"
                ]
              }
            </h2>
            <p className="review__review">
              {
                Text[french ? "fr" : "en"]["homepage-review"][
                  "review-2-comment"
                ]
              }
            </p>
            <Image
              width="156px"
              src={reviewNurse}
              alt="Review Author"
              roundedCircle
            ></Image>
            <p className="review__author">
              {Text[french ? "fr" : "en"]["homepage-review"]["review-2-author"]}
            </p>
            <p className="review__company">
              {
                Text[french ? "fr" : "en"]["homepage-review"][
                  "review-2-company"
                ]
              }
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  )
}

Review.prototype = {
  french: PropTypes.bool.isRequired,
}

export default Review
