import React from "react"
import Text from "../text.json"
import "../components/styles/homepage__steps.scss"

const Steps = ({ french }) => {
  return (
    <section className="homepage__steps">
      <h2 className="steps__heading">
        {Text[french ? "fr" : "en"]["homepage-steps"].heading}
      </h2>
      <ul className="process">
        {Text[french ? "fr" : "en"]["homepage-steps"].steps.map(
          (step, index) => (
            <li
              key={index}
              className="process__item d-flex flex-column align-items-center"
            >
              <span className="process__number">{index + 1}</span>
              <span className="process__title">{step.title}</span>
              <span className="process__subtitle">{step.text}</span>
            </li>
          )
        )}
      </ul>
    </section>
  )
}

export default Steps
