import React from "react"
import "./styles/homepage__benefit.scss"
import Cup from "../images/teaRockCup.png"
import Talking from "../images/talking.png"
import Like from "../images/like.png"
import Text from "../text.json"
import ProcessCard from "./process-card"
import "../components/styles/homepage__process.scss"
import { Container, Row } from "react-bootstrap"

const Process = ({ french }) => {
  let images = [Cup, Talking, Like]

  return (
    <section className="process--container">
      <Container fluid className="process__boot-container">
        <Row className="no-gutters justify-content-center">
          {Text[french ? "fr" : "en"]["homepage-process"].map((el, index) => (
            <ProcessCard
              key={index}
              heading={el.heading}
              text={el.text}
              bgColor={el.bgColor}
              image={images[index]}
            />
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Process
