import React from "react"
import ModalForm from "../components/modal-form"
import PropTypes from "prop-types"
import Text from "../text.json"
import { linkResolver } from "../utils/linkResolver"
import { Link } from "gatsby"
import "./styles/homepage__findwork__card.scss"

const FindworkCard = ({ french, job: jobNode }) => {
  const [modalShow, setModalShow] = React.useState(false)
  let job = jobNode.node
  let jobTitle = job.title
  let imageURL = job.image_de_l_emploi.url
  let salaryType = job.type_de_salaire
  let salary = salaryType !== "Stage non rémunéré" && job.salaire
    ? `${job.salaire}$ /${
        job.type_de_salaire === "Taux Horaire" ? "h" : "semaine"
      }`
    : null
  let location = `${job.city}, ${job.province}`
  console.log(job)
  let _meta = job._meta
  return (
    <div className="homepage card--container">
      <Link to={linkResolver(_meta)} className="homepage card__link">
        <div className="card__image">
          <img src={imageURL} alt="delivery"></img>
        </div>
      </Link>
      <div className="content--container">
        <Link to={linkResolver(_meta)} className="homepage card__link">
          <p className="card__title">{jobTitle}</p>
        </Link>
        <p className="card__location">{location}</p>
        {salary ? <p className="card__salary">{Text[french ? "fr" : "en"]["homepage-findwork"].salary} <span className="card__salary card__salary--amount">{salary}</span></p> : <p></p>}
        <button className="button--apply" onClick={() => setModalShow(true)}>
          {Text[french ? "fr" : "en"]["homepage-findwork"].button}
        </button>
      </div>
      <ModalForm
        french={french}
        jobTitle={jobTitle}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  )
}

FindworkCard.prototype = {
  french: PropTypes.bool.isRequired,
}

export default FindworkCard
