import React from "react"
import Text from "../text.json"
import { Link } from "gatsby"
import "./styles/homepage__cta.scss"

const Cta = ({ french }) => {
  let prefix = french ? "" : "/en"
  return (
    <section className="cta--section">
      <div className="cta__container">
        <div className="cta__content">
          <h2>{Text[french ? "fr" : "en"]["homepage-cta"].heading}</h2>
          <p>{Text[french ? "fr" : "en"]["homepage-cta"].subheader}</p>
        </div>
        <div className="cta__buttons">
          <Link to={`${prefix}/jobs`}>
            <button className="button--apply cta__button">
              {Text[french ? "fr" : "en"]["homepage-cta"].button__apply}
            </button>
          </Link>
          <Link to={`${prefix}/contact`} className="cta__button--button">
            <button className="button--apply button-contact">
              {Text[french ? "fr" : "en"]["homepage-cta"].button__contact}
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Cta
