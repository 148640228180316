import React from "react"
import PropTypes from "prop-types"
import OvalImage from "../images/Oval.svg"
import Women from "../images/women.png"
import Text from "../text.json"
import { Image, Container } from "react-bootstrap"
import "./styles/homepage__benefit.scss"

const Benefits = ({ french }) => (
  <section className="benefit--section">
    <Container fluid className="d-flex justify-content-center">
      <div className="benefit__container">
        <h2 className="benefit__header">
          {Text[french ? "fr" : "en"]["homepage-benefit"].heading}
        </h2>
        <p>{Text[french ? "fr" : "en"]["homepage-benefit"].subheader}</p>
        <ul className="benefit__list">
          {Text[french ? "fr" : "en"]["homepage-benefit"].benefits.map(
            (el, index) => (
              <li key={index}>{el}</li>
            )
          )}
        </ul>
      </div>
      <div className="benefit__img--container">
        <img className="benefit__circle" src={OvalImage} alt="oval"></img>
        <Image
          className="benefit__women"
          src={Women}
          alt="women"
          rounded
        ></Image>
      </div>
    </Container>
  </section>
)

Benefits.prototype = {
  french: PropTypes.bool.isRequired,
}

export default Benefits
