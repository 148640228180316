import React, { useState } from "react"
import PropTypes from "prop-types"
import { Col, Image, Fade } from "react-bootstrap"

const ProcessCard = ({ heading, text, image, bgColor }) => {
  const [jobOpen, setJobOpen] = useState(false)
  const textList = text.map(item => <li>{item}</li>)
  console.log(textList)
  return (
    <Col
      className="d-flex flex-column align-items-center process-container process-container--job"
      style={{ backgroundColor: bgColor }}
      onMouseEnter={() => setJobOpen(true)}
      onMouseLeave={() => setJobOpen(false)}
    >
      <Image width="126px" src={image}></Image>
      <p className="mt-3 text-center process__step">{heading}</p>
      <Fade in={jobOpen}>
        <div><ul>{textList}</ul></div>
      </Fade>
    </Col>
  )
}

ProcessCard.prototype = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
}

export default ProcessCard
