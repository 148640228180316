import React from "react"
import FindworkCard from "./homepage__findwork__card"
import Text from "../text.json"
import PropTypes from "prop-types"
import SwiperCore, { Autoplay } from "swiper"
import { useMediaQuery } from "react-responsive"
import { Container } from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import "./styles/homepage__findwork.scss"

const Findwork = ({ french, jobs }) => {
  let workCards = jobs.map((job, i) => (
    <SwiperSlide key={i} className="d-flex align-items-stretch">
      <FindworkCard french={french} job={job} key={i} />
    </SwiperSlide>
  ))

  SwiperCore.use([Autoplay])

  const isMobile = useMediaQuery({ maxWidth: "600px" })
  const isSmall = useMediaQuery({ maxWidth: "1600px" })

  return (
    <section className="findwork--section">
      <Container className="findwork__container">
        <h1 className="homepage__findwork--header">
          {french ? "Votre " : "Your "}
          <span className="homepage__findwork--highlight">
            {french ? "carrière" : "career"}
          </span>
          {french ? " commence ici" : " starts here"}
        </h1>
        <p>{Text[french ? "fr" : "en"]["homepage-findwork"].subheading}</p>
      </Container>
      <Swiper
        autoplay={isMobile ? { delay: 4000 } : { delay: 6000 }}
        spaceBetween={0}
        slidesPerView={isMobile ? 2 : isSmall ? 4 : 6}
        scrollbar={{ draggable: true }}
      >
        {workCards}
      </Swiper>
    </section>
  )
}

Findwork.prototype = {
  french: PropTypes.bool.isRequired,
}

export default Findwork
